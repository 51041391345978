<template>
  <main id="js-page-content" class="page-content" role="main" v-if='!loading && company'>
    <div class="moverflow-x-auto">
      <div
        class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white"
      >
        <Webhooks v-if="company.webhooks" :webhooks="company.webhooks" />
      </div>
    </div>
  </main>
</template>

<script>
import Webhooks from '@/components/Model/Company/Webhooks'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'CompanyWebhooks',
  components: {
    Webhooks
  },
  data() {
    return {
      loading: true
    }
  },
  created() {
    this.getMyCompany().then(() => {
      this.loading = false
    })
  },
  computed: {
    ...mapState('company', {
      company: (state) => state.company,
    }),
  },
  methods: {
    ...mapActions('company', ['getMyCompany']),
  },
}
</script>
