<template>
    <section>
        <div
            class="flex flex-col lg:flex-row p-8 justify-between items-start lg:items-stretch w-full"
        >
            <div
                class="w-full flex flex-col lg:flex-row items-start lg:items-top"
            ></div>
            <router-link to="/company/webhooks/create">
                <button
                    class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    {{ $t('companies.webhook.create_one') }}
                </button>
            </router-link>
        </div>
        <b-table
            class="table-striped overflow-x-scroll"
            backend-sorting
            :data="webhooks"
            :striped="true"
            :mobile-cards="true"
            :sticky-header="false"
            v-if="webhooks.length > 0"
        >
            <b-table-column
                field="id"
                label="ID"
                width="40"
                numeric
                v-slot="{ row }"
            >
                {{ row.id }}
            </b-table-column>
            <b-table-column
                sortable
                field="name"
                :label="$t('companies.name')"
                v-slot="{ row }"
            >
                {{ row.name }}
            </b-table-column>
            <b-table-column sortable field="url" label="URL" v-slot="{ row }">
                {{ row.url }}
            </b-table-column>
            <b-table-column
                sortable
                field="signature_secret"
                :label="$t('companies.signature')"
                v-slot="{ row }"
            >
                {{ row.signature_secret }}
            </b-table-column>
            <b-table-column :label="$t('companies.actions')" v-slot="{ row }">
                <router-link
                    :to="`/company/webhooks/${row.id}/edit`"
                    class="inline-flex items-center px-3 py-2 ml-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    <IconModif class="h-4 w-4" />
                </router-link>
            </b-table-column>
        </b-table>
        <div class="text-center" v-else>
            {{ $t('companies.webhook.not_found') }}
        </div>
    </section>
</template>

<script>
export default {
    name: 'CompanyWebhooks',
    props: {
        webhooks: {
            type: Array,
            required: true
        }
    }
}
</script>

<style></style>
