var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"flex flex-col lg:flex-row p-8 justify-between items-start lg:items-stretch w-full"},[_c('div',{staticClass:"w-full flex flex-col lg:flex-row items-start lg:items-top"}),_c('router-link',{attrs:{"to":"/company/webhooks/create"}},[_c('button',{staticClass:"inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"},[_vm._v(" "+_vm._s(_vm.$t('companies.webhook.create_one'))+" ")])])],1),(_vm.webhooks.length > 0)?_c('b-table',{staticClass:"table-striped overflow-x-scroll",attrs:{"backend-sorting":"","data":_vm.webhooks,"striped":true,"mobile-cards":true,"sticky-header":false}},[_c('b-table-column',{attrs:{"field":"id","label":"ID","width":"40","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}],null,false,3280815794)}),_c('b-table-column',{attrs:{"sortable":"","field":"name","label":_vm.$t('companies.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}}],null,false,228539768)}),_c('b-table-column',{attrs:{"sortable":"","field":"url","label":"URL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.url)+" ")]}}],null,false,4030080116)}),_c('b-table-column',{attrs:{"sortable":"","field":"signature_secret","label":_vm.$t('companies.signature')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.signature_secret)+" ")]}}],null,false,867759474)}),_c('b-table-column',{attrs:{"label":_vm.$t('companies.actions')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"inline-flex items-center px-3 py-2 ml-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",attrs:{"to":("/company/webhooks/" + (row.id) + "/edit")}},[_c('IconModif',{staticClass:"h-4 w-4"})],1)]}}],null,false,1300673483)})],1):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('companies.webhook.not_found'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }